import { cn } from '../../utils/cn';

const ArrowRightIcon = ({ className }: { className?: string }): JSX.Element => {
  return (
    <svg
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={cn('w-3 h-3 fill-current', className)}
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M4.30939 11.8047C3.93284 11.5175 3.89469 11.0188 4.22417 10.6906L7.64537 7.28342C7.80235 7.12708 7.80235 6.87292 7.64537 6.71658L4.22417 3.30935C3.89469 2.98122 3.93284 2.48246 4.30939 2.19534C4.68594 1.90823 5.25829 1.94148 5.58777 2.26961L9.74596 6.41081C10.0847 6.74815 10.0847 7.25185 9.74596 7.58919L5.58777 11.7304C5.25829 12.0585 4.68594 12.0918 4.30939 11.8047Z'
      />
    </svg>
  );
};

export { ArrowRightIcon };
